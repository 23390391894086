import React, { useEffect, useContext } from "react"
import { Link } from "gatsby"
// import SEO from "../components/seo"
import styled from '@emotion/styled'
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import formatDates, { formatAnchor } from "../utils/formatDates"
import { useEvents } from "../hooks/useEvents"
import { MIN_DEFAULT_MQ } from "../utils/presets"
import { CurrentContext } from "../layouts"
import Body from "../components/Body"


const Article = styled(`article`)({
  margin: `0 0 2rem 0`,
  minHeight: `26rem`,
  [MIN_DEFAULT_MQ]: {
    maxWidth: `calc(100vw - 20rem)`
  }
})

const Header = styled(`header`)({
  marginBottom: `1rem`,
  [`&::before`]: {
    display: `block`,
    content: `" "`,
    visibility: `hidden`,
    pointerEvents: `none`,
    marginTop: `-76px`,
    height: `76px`,
    [MIN_DEFAULT_MQ]: {
      marginTop: `-64px`,
      height: `64px`,
    }
  }
})

const IndexPage = ({ data, location }) => {
  const events = useEvents()
/*  const currentContext = useContext(CurrentContext)

  useEffect(() => {
    if (currentContext) {
      // 23082021
      const anchorTarget = document.getElementById(currentContext.id)
      if (anchorTarget) {
        setTimeout(() => {
          anchorTarget.scrollIntoView()
          // window.scroll(0, anchorTarget.offsetTop)
        }, 900)
      }
    }
  }, [])
*/
  return (
    <React.Fragment>
      {/*<SEO title="All events" />*/}
      {events.map(({ node }) => {
        const { displayDate } = formatDates(node.startDate, node.endDate)
        return (
          <Article key={node.slug.current} id={formatAnchor(node.startDate)}>
            <Header>
              <h3>
                <a href={node.eventLink} target="_blank">{displayDate}</a>
              </h3>
            </Header>
            <Body value={node._rawBody} />
          </Article>
        )
      })}
    {/*<EventImages />*/}
    </React.Fragment>
  )
}

export default IndexPage
